/*import css*/
import './css/bootstrap.css';
import './css/mainCSS.css';
import 'bootstrap'



/*alias para jquery en ambito $*/
window.$ = window.jQuery = require('jquery');
window.M =require('materialize');

