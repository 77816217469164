import React, {Suspense} from 'react';

import './load'
import PersistentDrawerLeft from "./component/menu/menu";
import {HashRouter as Router, Switch} from 'react-router-dom';
import AppProvider from './appProvider';
import RouteApp from "./routes";
import ReactDOM from "react-dom";
import BackdropEko from "./component/backdrop";
//id="PersistentDrawerLeft"
function Index() {
    return (
        <AppProvider>
        <PersistentDrawerLeft>
            <div id="alert-bootstrap"/>
            <Router>
                <Suspense delayMs={500}
                          fallback={<BackdropEko open={true}/>}>
                    <Switch>
                        <RouteApp/>
                    </Switch>
                </Suspense>
            </Router>
        </PersistentDrawerLeft>
    </AppProvider>

    );
}

ReactDOM.render(<Index/>, document.getElementById('root'));
