import React from 'react';
import {instancia} from "./js/funciones/datos";
import BackdropEko from "./component/backdrop";

export const Context = React.createContext();


export default class AppProvider extends React.Component {
    getOpen = () => this.state.open;
    setOpen = open => {
        this.setState({open})
        this.setLocalStorageItem({item: "open", data: open})
    }

    setMenu = menu => {
        this.setState({menu})
        this.setLocalStorageItem({item: "menu", data: menu})
    }

    setAddMenu = menu => {
        this.setMenu(this.getMenu().push(menu))
    }
    getMenu = () => this.state.menu;

    setUser = user => {
        this.setState({user})
        this.setLocalStorageItem({item: "usuario", data: user})
    }
    setToken = token => {
        this.setLocalStorageItem({item: "token", data: token});
    }
    getUser = () => this.state.user;

    getComunas = () => this.state.comunas;
    getRegiones = () => this.state.regiones;

    setModal = (op = undefined) => {

        this.setState({"modal": op !== undefined ? op : !this.state.modal});

    }

    setLocalStorageItem({item, data}) {
        localStorage.setItem(item, JSON.stringify(data))
    }

    getLocalStorageItem({key}) {
        if (localStorage.getItem(key)) {
            return JSON.parse(localStorage.getItem(key));
        }
        return null;
    }


    async set_close_session() {
        this.setMenu([]);
        this.setUser([]);
        this.setOpen(false);
        localStorage.clear();
        sessionStorage.clear();
        window.location.replace(window.location.origin);
    }

    state = {
        open: localStorage.getItem("open") ?
            JSON.parse(localStorage.getItem("open")) : false,
        setOpen: this.setOpen,
        getOpen: this.getOpen,
        setToken: this.setToken,
        cierreSesion: this.set_close_session,
        comunas: [],
        regiones: [],
        modal: false,
        menu: localStorage.getItem("menu") ? JSON.parse(localStorage.getItem("menu")) : [],
        user: localStorage.getItem("usuario") ? JSON.parse(localStorage.getItem("usuario")) : {},
        setMenu: this.setMenu,
        getMenu: this.getMenu,
        setUser: this.setUser,
        getUser: this.getUser,
        getComunas: this.getComunas,
        getRegiones: this.getRegiones,
        toogleModal: this.setModal,
        localStorage: {
            get: this.getLocalStorageItem,
            add: this.setLocalStorageItem
        }
    }


    async traerComunas() {
        try {
            let {data} = await instancia.get("/getComunas").then(x => x);
            this.setState({comunas: data.result.comunas});
        } catch (e) {
            console.log(e);
        }

    }

    async traerRegiones() {
        try {
            let {data} = await instancia.get("/getRegiones").then(x => x);
            this.setState({regiones: data.result.regiones});
        } catch (e) {
            console.log(e);
        }

    }

    render() {
        return (
            <Context.Provider value={{state: this.state}}>
                <BackdropEko open={this.state.modal}/>
                {this.props.children}
            </Context.Provider>
        )
    }

}
export const consumer = Context.Consumer;