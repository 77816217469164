import axios from 'axios';
import {alerta} from "./functionGeneral";
import React from "react";

//export const basePath = "http://localhost:8080";
 //export const basePath = "https://www.ekocard.cl:8080";
// export const basePath = "www.ekocard.cl:8080";

export const keyGoogle = "AIzaSyBZBVLws3GVSOUbIvoQ9507y6drtjFcNKI";

export const instancia = axios.create({
    // baseURL: basePath,
    timeout: 60000
})

instancia.interceptors.request.use(config => {
        if (localStorage.getItem("token")) {
            config.headers["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem("token"))}`;
           ///  console.log(config.headers["Authorization"] );
        }
        return config;
    },
    error => {
        Promise.reject(error);
    }
)

instancia.interceptors.response
    .use((response) => {
            return response.data;
        },
        function (error) {
            if (error.response) {
                let {data, status} = error.response;
                if (status === 403) {
                    alerta("Operación no permitida para el usuario.", "danger", {fixed: true});
                }
                return {error: status, data: data}
            }
            return {error: 500, data: {error: 'error'}}
        }
    )

/*exportar google maps geocode*/
export const geoCodeGoogle = async (lat, long) => {
    let calle = null;
    try {
        let {results} = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?key=${keyGoogle} &latlng=${lat},${long}&sensor=false`, {
            method: 'GET',
            mode: 'cors',
            cache: 'default',
            credentials: 'omit'
        }).then(x => x.json());
        calle = results[0].formatted_address;
    } catch (e) {

    }
    return calle;

}



