import {compareAsc, format} from 'date-fns'

// export const descargaConParamentros=(urls,datos,nombre)=>{
//     let titulo=nombre;
//     instancia.get(urls,datos).then((data)=>console.log(data))
//
//
// }


//convierte objetojson en parametros para ser enviados//
export const formatParametros = (parametros) => {
    let formData = new FormData();
    let params = Object.keys(parametros);
    let values = Object.values(parametros);
    params.map((key, indice) => {

        formData.append(`${key}`, values[indice]);
    });
    return formData;

}
/*recive parametros verifica si en este no hay ninguno nulo*/
export const validForm = (...argument) => {
    let res = true;
    argument.map((e) => {
        if (e == undefined || e == "") {
            res = false;
        }
        ;
    });
    return res;
}
/*funcion compara dos fechas*/
export const compararDosFechas = (dateI, dateF) => {

    return compareAsc(new Date(dateI), new Date(dateF))

}

/*funcion coloca nombre en el state de componente que no cumple con validacion retorna elemento formdata y
si formulario es valido*/
export const _validity = (form, state, message = {}, fn) => {
    let retorno = null;
    /*se reccore formulario */
    form.onChange = "";
    [...form.elements].map((e) => {
        validacionFormularioEtiquetas(e, state, message);
    })
    /*evento onchange en formulario*/
    form.onchange = (e) => {
        validacionFormularioEtiquetas(e.target, state, message);
        if (fn) {
            retorno = fn(e);
            console.log(retorno)
        }
    }

    return {valid: form.reportValidity(), data: new FormData(form), fn: retorno}

}
/*ingresa a estado variables y mensajes de erroreres de formularios*/
const validacionFormularioEtiquetas = (e, state, message) => {
    let target = e;
    target.setCustomValidity("");
    if (target.tagName == "INPUT") {
        let nombre = target.name;
        let mensajes = message[nombre] ? message[nombre] : "ingrese datos en este campo";
        if (target.validity.valid) {
            state.setState({[nombre]: undefined});
        } else {
            target.setCustomValidity(mensajes);
            state.setState({[e.name]: mensajes});
        }
    }
}
/****************************************************************************************/

export const _reset_validity = (form, state) => {

    [...form.elements].map((e) => {
        if (e.tagName == "INPUT") {
            state.setState({[e.name]: false});
        }

    })

}

export const _formatFechaCalendar = (fecha, split, join) => {
    let fechas = fecha.split(split).reverse().join(join);
    return fechas;
}

export const _formartFecha = (date, formato) => {
    return format(new Date(date), formato);
}

export const _fechaActual = (formato) => {
    return format(new Date(), formato);
}


/*crea alerta*/
export const alerta = (title, tipo, {fixed = false} = {}) => {

    let tipos = {
        warning: "alert-warning",
        danger: "alert-danger",
        succes: "alert-success",
        secondary: "alert-secondary"
    }

    let div = document.createElement("div");
    let strong = document.createElement("strong");
    strong.innerHTML = title;
    let clases = ['alert', tipos[tipo], 'alert-dismissible', 'fade', 'show', "ekoalerta"];
    let buton = document.createElement("button");
    let span = document.createElement("span");
    span.setAttribute("aria-hidden", "true");
    span.innerHTML = '&times;';
    buton.classList.add("close");
    buton.type = "button";
    buton.dataset.dismiss = "alert";
    buton.setAttribute("aria-label", "Close");
    buton.append(span);
    clases.map((clase) => {
        div.classList.add(clase);
    });
    div.setAttribute("rol", "alert");
    div.append(strong);
    div.append(buton);
    let cont_alert = document.getElementById("alert-bootstrap");
     cont_alert.style.position = "fixed";
    cont_alert.style.top = "0";
    cont_alert.style.left = "0";
    cont_alert.style.width = "100%"
    cont_alert.style.zIndex = 10000;
    cont_alert.append(div);
    if (!fixed) {
        setTimeout(() => {
            window.$(".alert").hide();
        }, 2800);
    }
}

export const fechaActual = () => {
    return new Date().toLocaleDateString().split("-").reverse().join("-");
}


export const exprecionesRegulares = {
    mayusculas: new RegExp(/[A-Z]/),
    minusculas: new RegExp(/[a-z]/),
    numeros: new RegExp(/[0-9]/)
}


/*formatea rut con .*/
export const formatRut = (rut) => {
    let indice = rut.length;

    let primerSegmento;
    let secondSegmento;
    let tercerSegmento;
    switch (indice) {
        case 8:
            primerSegmento = rut.substr(indice - 3, indice);
            secondSegmento = rut.substr(indice - 6, indice - 5);
            tercerSegmento = rut.substr(0, 2);
            rut = `${tercerSegmento}.${secondSegmento}.${primerSegmento}`;

            break;
        case 7:
            primerSegmento = rut.substr(indice - 3, indice);
            secondSegmento = rut.substr(indice - 6, indice - 4);
            tercerSegmento = rut.substr(0, 1);
            rut = `${tercerSegmento}.${secondSegmento}.${primerSegmento}`;
            break;
    }

    return rut;
}

