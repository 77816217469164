import React from "react";
import {Route} from 'react-router-dom';
import {Context} from "./appProvider";
import {routes_fiscalizador} from "./page/fiscalizador/routes-fiscalizador";
import {routesMaster} from "./page/maestros/routes-master";


/*rutas generales*/
const generales = {
    "/Mapas/MapaOnline": <Route path="/Mapas/MapaOnline" exact render={() => {
        window.open("#/general/map/index", "_blank");
        window.location.replace("#/index")
    }}/>,
    /*
        "/general/marcacionfechas/marcaciones": <Route path="/general/marcacionfechas/marcaciones" exact
                                                       component={React.lazy(() => import('./page/general/reportes/marcacionesCupoChoferMes'))}/>,
    */

    "/general/map/index": <Route path="/general/map/index" exact
                                 component={React.lazy(() => import('./page/general/mapa/mapaOnline/MapaOnlineAction'))}/>,

    "general/infracciones/exesodeconduccioncontinua": <Route path="/general/infracciones/exesodeconduccioncontinua"
                                                             exact
                                                             component={React.lazy(() => import('./page/general/reportes/marcacionesRangoFechas'))}/>,

    "/general/marcacionesmensual": <Route path="/general/marcacionesmensual" exact
                                          component={React.lazy(() =>
                                              import('./page/general/reportes/marcaciones-user'))}/>,

    "/general/marcacionesdiarias": <Route path="/general/marcacionesdiarias" exact
                                          component={React.lazy(() =>
                                              import('./page/general/reportes/marcaciones-diarias'))}/>,

    "/user/config/password": <Route path="/user/config/password" exact
                                    component={React.lazy(() => import("./page/user/page-config-user"))}/>,
    "/user/config/email": <Route path="/user/config/email" exact
                                 component={React.lazy(() => import("./page/user/page-config-email"))}/>

}


const rutas = {
    ...generales,
    ...routesMaster,
    ...routes_fiscalizador
}


export default class RouteApp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }


    /*busca url a dos niveles*/
    _findUrlMenu() {
        let menu = this.context.state.getMenu();
        let retorno = [];
        menu.map(x => {
            if (x.url && x.url !== "") {
                retorno.push(x.url);
                return x.url;
            } else if (x.submenu) {
                return x.submenu.map((z) => {
                    if (z.url && z.url !== "") {
                        retorno.push(z.url);
                        if (z.url === "/Mapas/MapaOnline") {
                            retorno.push("/general/map/index");
                        }
                        return z.url;
                    }
                })
            }
        });
        return retorno;
    }

    filterPage(id) {
        return Object.keys(rutas).filter(x => x.includes(id)).map(x => rutas[x]);
    }

    render() {
        return (
            !this.context.state.getOpen() ?
                <Route component={React.lazy(() => import("./component/login/loginComponent"))}/> :
                <>
                    <Route path="/index" exact
                           component={React.lazy(() => import("./page"))}/>

                    {this._findUrlMenu().map(x => this.filterPage(x))}

                </>
        )
    }


}
RouteApp.contextType = Context;


