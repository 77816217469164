import makeStyles from "@material-ui/core/styles/makeStyles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";

export default function BackdropEko({open}) {
    const useStyles = makeStyles(theme => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }));
    return <Backdrop
        className={useStyles().backdrop}
        open={open}
    >
        <CircularProgress color="inherit"/>
    </Backdrop>
}