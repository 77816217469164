import React from "react";
import {Route} from "react-router-dom";

export const routesMaster = {
    "/master/tarjeta": <Route path="/master/tarjeta" exact
                              component={React.lazy(() =>
                                  import('./tarjeta/index'))}/>,
    "/master/tarjeta/detail": <Route path="/master/tarjeta/detail/:id" exact
                                     component={React.lazy(() =>
                                         import('./tarjeta/tarjeta-form-detail'))}/>,
    "/master/tarjeta/create": <Route path="/master/tarjeta/create" exact
                                     component={React.lazy(() =>
                                         import('./tarjeta/tarjeta-form-create'))}/>,
    "/master/chip": <Route path="/master/chip" exact
                           component={React.lazy(() => import('./chip'))}/>,
    "/master/chip/detail": <Route path="/master/chip/detail/:id" exact
                                  component={React.lazy(() => import('./chip/chip-form-detail'))}/>,
//                                  
    "/master/equipo": <Route path="/master/equipo" exact
                             component={React.lazy(() => import('./equipo'))}/>,
    "/master/equipo/create": <Route path="/master/equipo/create" exact
                                    component={React.lazy(() => import('./equipo/equipo-form-create'))}/>,
    "/master/equipo/detail": <Route path="/master/equipo/detail/:id" exact
                                    component={React.lazy(() => import('./equipo/equipo-form-edit'))}/>,
//                                    
    "/master/chip/create": <Route path="/master/chip/create" exact
                                  component={React.lazy(() => import('./chip/chip-form-create'))}/>,
    "/master/maquina": <Route path="/master/maquina" exact
                              component={React.lazy(() => import('./maquina'))}/>,
    "/master/maquina/create": <Route path="/master/maquina/create" exact
                              component={React.lazy(() => import('./maquina/maquina-form-create'))}/>,

    "/master/maquina/detail": <Route path="/master/maquina/detail/:id" exact
                                     component={React.lazy(() => import('./maquina/maquina-form-edit'))}/>,
    "/master/trabajador": <Route path="/master/trabajador" exact
                                 component={React.lazy(() => import('./trabajador'))}/>,
        "/compensacionfestivostrabajador":<Route path="/compensacionfestivostrabajador" exact
    component={React.lazy(() => import('./compensacion'))}/>,       
    "/compensacionfestivostrabajador/create":<Route path="/compensacionfestivostrabajador/create/:rut_trabajador" exact
    component={React.lazy(() => import('./compensacion/compensacion-form-create'))}/>,    
    

}


// estoy aqui para agregar /compensacionfestivostrabajador