import {Route} from "react-router-dom";
import React from "react";

export const routes_fiscalizador = {
    "/nominas/empleadores": <Route path="/nominas/empleadores" exact
                                   component={React.lazy(() =>
                                       import('./nominas/empleadores'))}/>,
    "/nominas/empleadores/trabajadores": <Route path="/nominas/empleadores/:name/:rut" exact
                                                component={React.lazy(() =>
                                                    import('./nominas/empleadores/trabajadores-vigentes'))}/>,
    "/nominas/agrupaciones": <Route path="/nominas/agrupaciones" exact
                                    component={React.lazy(() =>
                                        import('./nominas/agrupaciones'))}/>,

    "/nominas/agrupaciones/child": <Route path="/nominas/agrupaciones/:name/:id" exact
                                          component={React.lazy(() =>
                                              import('./nominas/agrupaciones/empresas-adheridas'))}/>,

    "/nominas/trabajadores": <Route path="/nominas/trabajadores" exact
                                    component={React.lazy(() =>
                                        import("./nominas/trabajadores"))}/>,


    "/nominas/vehiculos": <Route path="/nominas/vehiculos" exact
                                 component={React.lazy(() =>
                                     import("./nominas/vehiculo"))}/>,

    "/reporte/infracciones/empleador": <Route path="/reporte/infracciones/empleador"
                                              component={React.lazy(() =>
                                                  import("./infracciones/empleador-index"))}/>,

    "/reporte/infracciones/trabajador": <Route path="/reporte/infracciones/trabajador"
                                               component={React.lazy(() =>
                                                   import("./infracciones/trabajador-index"))}/>,

    "/reportemensualmarcaciones": <Route path="/reportemensualmarcaciones"
                                         component={React.lazy(() =>
                                             import("./mensualesDeMarcaciones"))}/>,

    "/reporte/marcaciones/trabajador": <Route path="/reporte/marcaciones/trabajador"
                                              component={React.lazy(() =>
                                                  import("./marcaciones/trabajador.js"))}/>,

    "/reporte/marcaciones/empleador": <Route path="/reporte/marcaciones/empleador"
                                             component={React.lazy(() =>
                                                 import("./marcaciones/empleador.js"))}/>,

    "/reporte/bitacora/trabajador": <Route path="/reporte/bitacora/trabajador"
                                           component={React.lazy(() =>
                                               import("./bitacora"))}/>,

    "/reporte/compensacionfestivos/trabajador": <Route path="/reporte/compensacionfestivos/trabajador"
                                                       component={React.lazy(() =>
                                                           import("./compensacion"))}/>
}