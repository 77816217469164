import React from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import {Context} from '../../appProvider';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import Typography from "@material-ui/core/Typography";
import {formatRut} from "../../js/funciones/functionGeneral";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import {useStyles} from "./styles-menu";


export default function PersistentDrawerLeft(props) {
    const contexto = React.useContext(Context);
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [active, setActive] = React.useState(-1);
    let {rol, nombre, nombre_rol, razon_social, rut, digito, rut_empresa, digit} = contexto.state.getUser();
    const isFiscalizador = rol === "2400"
    rut = rut ? formatRut(rut) : "";
    rut_empresa = rut_empresa ? formatRut(rut_empresa) : "";
    /*funcion cierra session*/
    const cierreSession = async () => {
        contexto.state.cierreSesion();
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleClick = (e, url) => {

        if (url != "") {
            window.location.href = "#" + url;
            setOpen(false);
        }

        let activo = active != e ? e : -1;
        setActive(activo)
    }

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <div className={clsx(classes.toolbarItems)}
                         style={contexto.state.open ? {} : {display: "none"}}>

                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            className={clsx(classes.menuButton, open && classes.hide)}
                        >
                            <MenuIcon/>

                        </IconButton>

                        <Button onClick={handleDrawerOpen}
                                variant="contained"
                                color={"primary"}
                                style={isFiscalizador ? {backgroundColor: 'transparent'} : {display: 'none'}}>
                            FISCALIZADOR D.T.
                        </Button>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div style={{paddingTop: "1em"}}>
                    <div className={classes.drawerHeader}>
                    </div>
                    <List style={{paddingTop: 0}}>
                        <ListItem alignItems="flex-start" style={{paddingTop: 0}}>
                            <ListItemText
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            className={classes.inline}
                                            color="textPrimary"
                                        >
                                            Nombre:
                                        </Typography>
                                        {" " + nombre}<br/>
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            className={classes.inline}
                                            color="textPrimary"
                                        >
                                            Rut:
                                        </Typography>
                                        {" " + rut + "-" + digito}<br/>

                                        <Typography
                                            component="span"
                                            variant="body2"
                                            className={classes.inline}
                                            color="textPrimary"
                                        >
                                            Razón social:
                                        </Typography>
                                        {" " + razon_social}<br/>
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            className={classes.inline}
                                            color="textPrimary"
                                        >
                                            Rut empresa:
                                        </Typography>
                                        {" " + rut_empresa + "-" + digit}<br/>
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            className={classes.inline}
                                            color="textPrimary"
                                        >
                                            Rol:
                                        </Typography>
                                        {" " + nombre_rol}
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                    </List>
                </div>
                <Divider/>
                <List>
                    {contexto.state.getMenu().map(({titulo, url, submenu}, index) => (
                        <React.Fragment>
                            <ListItem key={index + "li"} button onClick={(e) => {
                                handleClick(index, url)
                            }}>

                                <ListItemText primary={titulo}/>
                                {submenu ? active == index ? <ExpandLess/> : <ExpandMore/> : null}

                            </ListItem>

                            <Collapse key={index} in={active == index ? true : false} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>

                                    {submenu ? submenu.map(({titulo, url, submenu}, index) => (

                                        <ListItem button className={classes.nested} onClick={(e) => {
                                            handleClick(index, url)
                                        }}>

                                            <ListItemText primary={"- " + titulo}/>
                                        </ListItem>


                                    )) : null}
                                </List>
                            </Collapse>

                        </React.Fragment>

                    ))
                    }
                </List>
                <Divider/>
                <List>
                    <ListItem button onClick={cierreSession}>
                        <ListItemIcon><AccountBoxIcon/></ListItemIcon>
                        <ListItemText primary={"Cerrar sesion"}/>
                    </ListItem>
                </List>
                <Divider/>
                <Divider/>
            </Drawer>
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: open,
                })} style={{
                maxHeight: "100%",
                overflow: "auto"
            }}
            >
                <Backdrop open={open}
                          className={classes.backdrop}
                          onClick={() => {
                              setOpen(false);
                          }}
                />
                <div className={classes.drawerHeader}/>
                {props.children}
            </main>
        </div>
    );
}